import { ErrorMessage, Field, Form, Formik } from "formik";
import { useEffect, useState } from "react";
import { useLocation, useNavigate, useParams, useSearchParams } from "react-router-dom";
import { toast } from "react-toastify";
import * as Yup from "yup";
import networkService from "~/services/network.service";
import Carousel from "~/shared/components/carousel";
import CustomSelect from "~/shared/components/customSelect";
import PageHeader from "~/shared/components/page-header.component";
import { UserRole } from "~/shared/config";
import {
  DEVICE_LICENSE_DETAILS,
  GET_LICENSES_BY_ORG_ID,
  GET_MANUFACTURER,
  ORGANIZATION_PROBES_API,
  PROBE_DETAILS_API,
  REGISTER_DEVICE,
  UPDATE_DEVICE_ON_LICENSE,
} from "~/shared/constants/api";
import { handleError } from "~/shared/utils/errors.util";
import { validateNullishObjectFields } from "~/shared/utils/helper.util";
import useAuthStore from "~/store/auth.store";
import useSidebarStore from "~/store/sidebar.store";
import CustomModal from "~/shared/components/customModal";
import { ChevronLeft, Maximize } from "lucide-react";
import GenericValidationInput from "../../../../../shared/components/generic-validation-input";
import FullScreenModal from "~/shared/components/full-screen-modal";

interface ProbeData {
  device: {
    id: string;
    externalDeviceId: string;
    nickName: string;
    isActive: boolean;
    modelId: string;
    organization: {
      id: string;
      name: string;
      description: string;
      createdAt: string;
      updatedAt: string;
    };
    registrationStatus: string;
    createdAt: string;
    updatedAt: string;
    organizationId: string;
  };
  owner: {
    id: string;
    firstName: string;
    lastName: string;
    email: string;
  };
}

const UpdateProbeDetails = () => {
  const [isFullScreen, setIsFullScreen] = useState(false);
  const [openModal, setModalOpen] = useState(false);
  const [isValid, setIsValid] = useState(false);
  const [initialValues, setInitialValues] = useState({
    manufacturer: {
      name: "",
      id: "",
      models: [{ modelName: "", id: "", instructions: "" }],
    },
    model: { modelName: "", id: "" },
    externalDeviceId: "",
    nickName: "",
    planName: "N/A",
    licenseId: "N/A",
  });
  const [manufacturers, setManufacturers] = useState([]);
  const [models, setModels] = useState([]);
  const navigate = useNavigate();
  const { orgId, deviceId } = useParams();
  const [searchParams] = useSearchParams();
  const [collapsed] = useSidebarStore((state) => [state.collapsed]);
  const licenseId = searchParams.get("licenseId");
  const [images, setImages] = useState<any[]>([]);
  const { user } = useAuthStore((state) => ({ user: state.user }));
  const location = useLocation();

  const validationRules = [
    {
      rule: (value: string) => /^[A-Za-z]{2}/.test(value),
      message: "Must start with 2 letters",
    },
    {
      rule: (value: string) => value.length === 10,
      message: "Contain 10 characters",
    },
    {
      rule: (value: string) => !/[()]/.test(value),
      message: "Doesn't contain parentheses",
    },
  ];

  const handleValidationChange = (status) => {
    setIsValid(status);
  };

  const handleFindItClick = () => {
    event.stopPropagation();
    event.preventDefault();
    setModalOpen(true);
  };


  const fetchModelImages = async (modelId: string) => {
    try {
      const galleryResponse = await networkService.get<any>(
        `${GET_MANUFACTURER}/models/${modelId}/gallery`
      );

      const imagePromises = galleryResponse.data.gallery.map(
        async (img: any) => {
          const imageResponse = await networkService.get<Blob>(
            `${GET_MANUFACTURER}/models/${modelId}/gallery/${img.id}`,
            null,
            { responseType: "blob" }
          );
          if (!(imageResponse instanceof Blob)) {
            throw new Error("Response is not a Blob");
          }
          return URL.createObjectURL(imageResponse);
        }
      );
      setImages(await Promise.all(imagePromises));
    } catch (error: any) {
      handleError({ error: error, message: error.message, level: "error" });
      setImages([]);
    }
  };

  useEffect(() => {
    const fetchManufacturers = async () => {
      try {
        const response = await networkService.get<any>(GET_MANUFACTURER);
        setManufacturers(response.data);
        return response.data;
      } catch (error: any) {
        handleError({ error: error, message: error.message, level: "error" });
      }
    };
    const fetchDevice = async (manufacturers) => {
      try {
        const response = await networkService.get<any>(
          `${REGISTER_DEVICE}/device/${deviceId}`
        );
        const licenseResponse = await networkService.get<any>(
          `${DEVICE_LICENSE_DETAILS}/${deviceId}`
        );
        const data: ProbeData = response.data;
        const licenseData = licenseResponse.data[0];

        const manufacturer = manufacturers.find((man) =>
          man.models.some((model) => model.id === data.device.modelId)
        );
        const model = manufacturer
          ? manufacturer.models.find(
            (model) => model.id === data.device.modelId
          )
          : { modelName: "", id: "" };

        setInitialValues({
          manufacturer: manufacturer
            ? {
              name: manufacturer.name,
              id: manufacturer.id,
              models: manufacturer.models,
            }
            : {
              name: "",
              id: "",
              models: [{ name: "", id: "", instructions: "" }],
            },
          model: model
            ? { modelName: model.modelName, id: model.id }
            : { modelName: "", id: "" },
          externalDeviceId: data.device.externalDeviceId || "",
          nickName: data.device.nickName || "",
          licenseId: licenseData?.id ?? "N/A",
          planName: licenseData?.subscription?.plan?.name ?? "N/A",
        });
        if (manufacturer) {
          setModels(manufacturer.models);
        }
        fetchModelImages(model.id);
      } catch (error: any) {
        handleError({ error: error, message: error.message, level: "error" });
      }
    };
    const fetchLicense = async () => {
      try {
        if (licenseId) {
          const licenseResponse = await networkService.get<any>(
            `${GET_LICENSES_BY_ORG_ID}/${user?.organizationId}`
          );
          const licenseData = licenseResponse.data.results.filter(
            (f) => f.id === licenseId
          )[0];
          setInitialValues((prev) => ({
            ...prev,
            licenseId: licenseData?.id ?? "N/A",
            planName: licenseData?.subscription?.plan?.name ?? "N/A",
          }));
        }
      } catch (error: any) {
        handleError({ error: error, message: error.message, level: "error" });
      }
    };
    const getProbeDetails = async () => {
      try {
        const organizationProbesResponse = await networkService.get<any>(
          `${ORGANIZATION_PROBES_API}/${user?.organizationId}`
        );
        const probeCount = organizationProbesResponse.data.results.length;
        setInitialValues((prev) => ({
          ...prev,
          nickName: `probe-${probeCount > 0 ? probeCount : 1}`,
        }));
      } catch (error: any) {
        handleError({ error: error, message: error.message, level: "error" });
      }
    };
    user?.role === UserRole.Member && getProbeDetails();
    fetchManufacturers().then((data) => {
      if (deviceId !== "new") {
        fetchDevice(data);
      } else {
        fetchLicense();
      }
    });
  }, [deviceId, orgId, licenseId, user?.organizationId, user?.role]);

  const handleUpdate = async (values: {
    manufacturer: {
      name: string;
      id: string;
      models: {
        modelName: string;
        instructions: string;
        id: string;
      }[];
    };
    model: { id: string; modelName: string };
    externalDeviceId: string;
    nickName: string;
  }) => {
    console.log("values", values);
    if (
      !validateNullishObjectFields<{
        manufacturer: {
          name: string;
          id: string;
          models: {
            modelName: string;
            instructions: string;
            id: string;
          }[];
        };
        model: { id: string; modelName: string };
        externalDeviceId: string;
        nickName: string;
      }>(values)
    ) {
      toast.error("Please fill all the fields");
      return;
    }
    try {
      const updateManufacturerResponse = await networkService.put<any>(
        `${GET_MANUFACTURER}/models/${values.model.id}`,
        {
          manufacturerId: values.manufacturer.id,
        }
      );
      if (updateManufacturerResponse) {
        toast.success("Manufacturer updated successfully");
        const probeUpdateResponse = await networkService.put<any>(
          `${PROBE_DETAILS_API}/${orgId}/${deviceId}`,
          {
            modelId: values.model.id,
            externalDeviceId: values.externalDeviceId,
            nickName: values.nickName,
          }
        );
        if (probeUpdateResponse) {
          toast.success("Probe details updated successfully");
          navigate("/licenses");
        }
      }
    } catch (error: any) {
      handleError({ error: error, message: error.message, level: "error" });
    }
  };

  const handleCreateNewProbe = async (values: {
    model: { id: string; modelName: string };
    externalDeviceId: string;
    nickName: string;
  }) => {
    try {
      if (!licenseId) {
        toast.error("Please select a license to assign to this probe");
        return;
      }
      const response = await networkService.post<any>(
        `${REGISTER_DEVICE}/${orgId}/register`,
        {
          nickName: values.nickName,
          externalDeviceId: values.externalDeviceId,
          modelId: values.model.id,
          isActive: true,
        }
      );
      toast.success("Probe Created Successfully");
      if (response) {
        const deviceId = response.data.id;
        const addLicenseResponse = await networkService.put<any>(
          `${UPDATE_DEVICE_ON_LICENSE}/${licenseId}`,
          {
            deviceId: deviceId,
          }
        );
        if (addLicenseResponse) {
          toast.success("License assigned successfully");
          navigate("/licenses?downloadApp=true");
        }
      }
    } catch (error: any) {
      handleError({ error: error, message: error.message, level: "error" });
    }
  };

  const validationSchema = Yup.object({
    manufacturer: Yup.object({
      name: Yup.string(),
      id: Yup.string().required("Manufacturer is required"),
      models: Yup.array().of(
        Yup.object().shape({
          name: Yup.string(),
          id: Yup.string(),
          instructions: Yup.string(),
        })
      ),
    }).required("This field is required"),
    model: Yup.object({
      id: Yup.string().required("Model is required"),
      modelName: Yup.string(),
    }).required("This field is required"),
    externalDeviceId: Yup.string()
      .test("starts-with-letters", "", (value) =>
        /^[A-Za-z]{2}/.test(value)
      )
      .test("exact-length", "", (value) =>
        value ? /^[A-Za-z0-9]{10}$/.test(value) : true
      )
      .test("no-parentheses", "", (value) =>
        value ? /^[^(^)]*$/.test(value) : true
      )
      .test("letters-numbers", "", (value) =>
        value ? /^(?=.*[A-Za-z])(?=.*\d)/.test(value) : true
      )
      .required("This field is required"),
    nickName: Yup.string().required("Probe Alias is required"),
  });


  const isAttachProbe = location.pathname.includes("new");
  const headerText = isAttachProbe ? "Attach Probe" : "Edit probe"


  return (
    <div
      className={`flex flex-col flex-grow px-12 py-10 overflow-hidden ${collapsed ? `${user?.role === UserRole.Admin ? "ml-20" : "md:ml-20"}` : `${user?.role === UserRole.Admin ? "ml-72" : "md:ml-72"}`} transition-all duration-300 pb-24`}>


      {/* <PageHeader title="Attach Probe" showBack /> */}

      <div className="relative">
        {/* Header Section */}
        <div className="flex gap-4 items-center mb-3 justify-start lg:justify-start shadow-none lg:shadow-none">
          <button
            onClick={() => navigate(-1)}
            className="border-2 border-black-600 p-2 rounded-full lg:hidden z-50"
          >
            <ChevronLeft size={24} />
          </button>
          <h1 className="text-[20px] lg:text-3xl font-bold font-space-grotesk text-center md:text-left ml-[3.4rem] md:ml-1 lg:ml-0">
            {headerText}
          </h1>
        </div>
        <div className="lg:block hidden border-t border-[var(--Stroke,#EFEFF4)] mt-5"></div>
        {/* Drop Shadow on Mobile, Horizontal Line on Desktop */}
        <div className="border-t lg:border-t-0 border-[var(--Stroke,#EFEFF4)] shadow-md lg:shadow-none absolute bottom-[-0.25rem] h-28 w-[1000px] left-[-55px] right-0"></div>
      </div>

      <div className="flex md:flex-row flex-col gap-1 h-full mt-5">
        <Formik
          className="md:w-1/2 w-full"
          enableReinitialize
          initialValues={initialValues}
          validationSchema={validationSchema}
          onSubmit={(values, actions) => {
            if (deviceId === "new") {
              handleCreateNewProbe(values);
            } else {
              handleUpdate(values);
            }
            actions.setSubmitting(false);
          }}>
          {({ isSubmitting, setFieldValue, values }) => (
            <Form className="md:w-1/2 mt-4">
              <div className="mt-0">
                <div className="sm:px-0 mt-0">
                  <h3 className="text-[18px] lg:w-[90%] w-full font-normal leading-6 text-Neutral-900 font-space-grotesk">
                    Give us the details of your probe so we can link it to your license.
                  </h3>
                </div>
                {/* manufacturer info */}
                <div className="flex flex-col gap-6 max-w-[500px] mt-8">
                  <div>
                    <label
                      htmlFor="manufacturer.id"
                      className="block text-md font-bold leading-6 text-gray-900 font-space-grotesk pb-2">
                      Manufacturer
                    </label>
                    <CustomSelect
                      id="manufacturer.id"
                      name="manufacturer.id"
                      selectedValue={values.manufacturer.name}
                      onChangeHandler={(value: any) => {
                        setFieldValue("manufacturer", value);
                        setFieldValue("model", { modelName: "", id: "" });
                        setFieldValue("externalDeviceId", "");
                        setImages([]);
                        setModels(value.models);
                      }}>
                      {manufacturers.map((manufacturer: any) => (
                        <option key={manufacturer.id} value={manufacturer}>
                          {manufacturer.name}
                        </option>
                      ))}
                    </CustomSelect>
                    <ErrorMessage
                      name="manufacturer.id"
                      component="p"
                      className="text-red-500 text-xs mt-2"
                    />
                  </div>

                  <div>
                    <label
                      htmlFor="model.id"
                      className="block text-md font-bold leading-6 text-gray-900 font-space-grotesk pb-2">
                      Model
                    </label>
                    <CustomSelect
                      id="model.id"
                      name="model.id"
                      selectedValue={values.model.modelName}
                      onChangeHandler={(value: any) => {
                        setFieldValue("model", value);
                        fetchModelImages(value.id);
                      }}>
                      {models.map((model: any) => (
                        <option key={model.id} value={model}>
                          {model.modelName}
                        </option>
                      ))}
                    </CustomSelect>
                    <ErrorMessage
                      name="model.modelName"
                      component="p"
                      className="text-red-500 text-xs mt-2"
                    />
                  </div>
                </div>

                {/* Image Modal on Mobile*/}
                <div className="md:hidden relative">
                  <CustomModal
                    isOpen={openModal}
                    onClose={() => setModalOpen(false)}
                    showCloseButton={true}
                    width="min-w-[350px]"
                  >
                    <div>
                      <p className="font-space-grotesk text-center mb-4 text-xl font-medium">
                        {values.model.modelName} Serial Number
                      </p>
                      <p className="font-space-grotesk my-4 w-72">
                        The serial number is marked on the probe. It contains 10 characters, starting with letters.
                      </p>
                    </div>
                    <div className="md:hidden min-h-[220px]">
                      {images.length === 0 ? (
                        <div className="h-full flex items-center justify-center">
                          <img
                            src="/defaultImage.jpeg"
                            alt="defaultImage"
                            className="w-[290px] h-full object-contain max-h-[350px] object-left-top"
                          />
                        </div>
                      ) : (
                        <div className="h-full flex items-center justify-center">
                          <Carousel images={images} interval={6000} />
                        </div>
                      )}
                    </div>

                    {isFullScreen && (
                      <FullScreenModal
                        images={images}
                        isOpen={isFullScreen}
                        onClose={() => setIsFullScreen(false)}
                      />
                    )}

                    <div className="absolute bottom-14 right-12 z-50 flex space-x-4">
                      {!isFullScreen && (
                        <button
                          className="bg-[#C4C6EE] rounded-full p-2 shadow-md"
                          onClick={() => setIsFullScreen(true)}
                          type="button"
                        >
                          <Maximize />
                        </button>
                      )}
                    </div>
                  </CustomModal>
                </div>

                {/* probe info */}
                <div className="flex flex-col gap-3 max-w-[500px] mt-6">
                  <div>
                    <div className="flex items-center justify-between">
                      <label
                        htmlFor="externalDeviceId"
                        className="block text-md font-bold leading-6 text-gray-900 font-space-grotesk pb-2">
                        Probe Serial Number
                      </label>
                      {handleFindItClick && (
                        <button
                          className="font-space-grotesk underline lg:hidden pb-2"
                          onClick={handleFindItClick}
                          type="button"
                        >
                          Find it
                        </button>
                      )}
                    </div>
                    <GenericValidationInput
                      setFieldValue={setFieldValue}
                      onValidationChange={handleValidationChange}
                      onFindItClick={handleFindItClick}
                      name="externalDeviceId"
                      placeholder="ex: AB12458364"
                      validationRules={validationRules}
                    />
                  </div>

                  <div className="mt-3">
                    <label
                      htmlFor="nickName"
                      className="block text-md font-bold leading-6 text-gray-900 font-space-grotesk pb-2">
                      Probe Name
                    </label>
                    <Field
                      id="nickName"
                      name="nickName"
                      type="text"
                      // value={initialValues.nickName}
                      placeholder="Enter Probe Alias"
                      className="font-space-grotesk px-4 py-3 w-full rounded-lg border-[1px] border-[#D0D0D6] placeholder-gray input-text focus:outline-none"
                    />
                    <ErrorMessage
                      name="nickName"
                      component="p"
                      className="text-red-500 text-xs mt-2"
                    />
                  </div>
                </div>
              </div>
              {/* Buttons */}
              <div className="mt-10 flex md:flex-row flex-col flex-wrap gap-4">
                <div className="flex justify-center items-center lg:w-36 bg-angular-gradient rounded-full p-0.5">
                  <button
                    type="submit"
                    className="bg-rounded-full border text-md font-semibold sm:text-base md:text-base font-space-grotesk w-full bg-white-a700 rounded-full p-1.5 py-2"
                    disabled={isSubmitting}>
                    Update
                  </button>
                </div>
                <button
                  type="button"
                  onClick={() => navigate("/licenses")}
                  className="hidden lg:block py-2 px-10 rounded-full border-2 border-[#E0E0E5] text-md sm:text-base md:text-base font-space-grotesk">
                  Cancel
                </button>
              </div>
            </Form>
          )
          }
        </Formik >

        <div className="hidden md:block w-1/2 mt-4 left-0 bg-[#F8F8FB] p-7 rounded-lg max-h-fit" >
          <h2 className="font-space-grotesk text-xl font-bold">Find your serial number</h2>
          <p className="mt-5 font-space-grotesk leading-tight mb-5">
            The serial number is marked on the probe. It contains 10 characters, starting with letters.
          </p>
          <div className="w-full mt-4 flex justify-center items-center">
            {images.length === 0 ? (
              <img
                src="/defaultImage.jpeg"
                alt="defaultImage"
                className="h-auto w-auto max-w-[90%] object-contain border-gray-200 p-2 rounded-lg"
              />
            ) : (
              <div className="relative w-full max-h-[450px]">
                <Carousel images={images} interval={6000} />
              </div>
            )}
          </div>
        </div >
      </div >
    </div >
  );
};

export default UpdateProbeDetails;
