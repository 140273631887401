import { Link } from "react-router-dom";
import PageHeader from "~/shared/components/page-header.component";
import useAuthStore from "~/store/auth.store";
import useSidebarStore from "~/store/sidebar.store";
import { UserRole } from "~/shared/config";
import { Text, Box, Card, Separator, Flex } from "@radix-ui/themes";
import { ChevronRight, LogOutIcon } from "lucide-react";

export default function Profile() {
  const { user } = useAuthStore((state) => ({
    user: state.user,
  }));
  const [collapsed] = useSidebarStore((state) => [state.collapsed]);


  const formatNameInitials = () => {
    if (user?.firstName && user?.lastName) {
      return `${user?.firstName.charAt(0)}${user?.lastName.charAt(0)}`;
    }
    return "T";
  }

  return (
      <div
        className={`flex flex-col flex-grow lg:px-12 lg:py-4 p-8 overflow-hidden ${collapsed ? `${user?.role === UserRole.Admin ? "ml-20" : "md:ml-20"}` : `${user?.role === UserRole.Admin ? "ml-72" : "md:ml-72"}`} transition-all duration-300 pb-24`}>
        <h1 className="font-space-grotesk text-[34px] font-bold mt-6">Profile</h1>
        <Box>
          <Card size="1" className="font-space-grotesk p-6 shadow-md mt-7">
            <Flex gap="3" align="center">
              <Text className="p-3 bg-[#EDEDFA] rounded-full text-[#4C4097] uppercase text-lg font-bold">
                {formatNameInitials()}
              </Text>
              <Box>
                <Text as="div" size="5" weight="bold" className="capitalize">
                  {user?.firstName ? `${user?.firstName} ${user?.lastName}` : "N/A"}
                </Text>
                <Text
                  as="div"
                  size="3"
                  className="w-62"
                >
                  <span className="block lg:hidden md:hidden">
                    {user?.email && user.email.length > 18
                      ? `${user.email.slice(0, 18)}...`
                      : user?.email || "N/A"}
                  </span>
                  <span className="hidden lg:block md:block">
                    {user?.email || "N/A"}
                  </span>
                </Text>
              </Box>
            </Flex>
            <Separator orientation="horizontal" size="4" className="mt-5" />
            <Link to="/profile/update-user">
              <Flex justify="between" align="center" className="mt-5">
                <Text className="text-[19px]">
                  Edit profile
                </Text>
                <ChevronRight />
              </Flex>
            </Link>
            <Separator orientation="horizontal" size="4" className="mt-5" />
            <Link to="/profile/reset-password">
              <Flex justify="between" align="center" className="mt-5">
                <Text className="text-[19px]">
                  Change password
                </Text>
                <ChevronRight />
              </Flex>
            </Link>
          </Card>
        </Box>
  
  
        <div className="mt-7 flex md:flex-row flex-col flex-wrap gap-4">
          <div className="flex justify-center items-center lg:w-44 bg-angular-gradient rounded-full p-0.5">
            <Link
              to={`/logout`}
              className="flex justify-center items-center gap-2 bg-rounded-full border text-[18px] font-semibold sm:text-base md:text-base font-space-grotesk w-full bg-white-a700 rounded-full p-2">
              <LogOutIcon size={19} />
              Log out
            </Link>
          </div>
        </div>
      </div>
    );
}
