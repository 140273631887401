import clsx from "clsx";
import { memo, useCallback } from "react";
import { Link } from "react-router-dom";
import { UserProfileProps } from "./custom-sidebar";
import { LogOutIcon } from "lucide-react";
import useAuthStore from "~/store/auth.store";

export const UserProfile: React.FC<
  UserProfileProps<{
    id?: string;
    role?: string;
    email?: string;
    firstName?: string;
    lastName?: string;
  }>
> = memo(
  ({
    isActive,
    collapsed,
    userProfile,
    handleLogout,
    setIsCollapsed,
    profileClassName,
    isActiveClassName = "font-semibold bg-blue-300",
  }) => {
    const handleCollapse = useCallback(
      () => setIsCollapsed(false),
      [setIsCollapsed]
    );
    const getProfileInitial = useCallback(() => {
      return userProfile?.firstName?.charAt(0).toUpperCase() || "U";
    }, [userProfile]);

    const user = useAuthStore((state) => state.user);

    return (
      <div
        className={clsx(
          !collapsed && isActive && isActiveClassName,
          !collapsed && "hover:bg-zinc-100",
          "flex items-center gap-x-4 text-sm leading-6 text-gray-900 cursor-pointer overflow-hidden",
          profileClassName
        )}>
        <div className="flex justify-between w-full">
          <div className="py-4 px-6 w-3/4">
            {!collapsed ? (
              <Link to={`/profile/${user?.userId}`}>
                <div className="font-semibold text-wrap truncate">
                  {userProfile?.firstName}
                </div>
                <div className="text-neutral-600">
                  {userProfile?.email && userProfile?.email.length > 18 ? `${userProfile.email.slice(0, 18)}...`: userProfile?.email || "N/A"}
                </div>
              </Link>
            ) : (
              <button
                onClick={handleCollapse}
                className="absolute border-2 rounded-full text-2xl w-10 h-10 right-5 bottom-5 hover:border-zinc-300 hover:bg-background-gradient transition-all duration-300">
                {getProfileInitial()}
              </button>
            )}
          </div>
          {!collapsed && (
            <button
              className="py-3 px-4 w-1/4 hover:bg-zinc-200"
              onClick={handleLogout}>
              <LogOutIcon />
            </button>
          )}
        </div>
      </div>
    );
  }
);
