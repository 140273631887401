import { useEffect, useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import networkService from "~/services/network.service";
import PageHeader from "~/shared/components/page-header.component";
import Loader from "~/shared/components/ui/customLoader";
import ErrorPage from "~/shared/components/ui/error-page";
import { DEVICE_DETAILS_API, GET_MANUFACTURER } from "~/shared/constants/api";
import { formatDate } from "~/shared/utils/helper.util";
import useSidebarStore from "~/store/sidebar.store";
import { handleError } from "~/shared/utils/errors.util";
import useAuthStore from "~/store/auth.store";
import { UserRole } from "~/shared/config";
import { ChevronLeft } from "lucide-react";

interface DeviceDetails {
  id: string;
  externalDeviceId: string;
  nickName: string;
  currentPlan: string;
  manufacturer: string;
  model: string;
  licenseId: string;
  creationDate: string;
  expiryDate: string;
  createdAt: string;
  licenseCreatedAt: string;
  licenseExpiryDate: string;
}

interface Probe {
  id: string;
  externalDeviceId: string;
  nickName: string;
  licenseId: string;
  model: string;
  plan: string;
  name: string;
  createdAt: string;
  expiryDate: string;
  manufacturer: string;
  organization: {
    id: string;
    name: string;
  };
  licenseCreatedAt: string;
  licenseExpiryDate: string;
}

export default function DeviceDetailsPage() {
  const { deviceId } = useParams(); // Get the device ID from the URL
  const [deviceDetails, setDeviceDetails] = useState<Probe | null>(null);
  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<string | null>(null);
  const navigate = useNavigate();
  const [collapsed] = useSidebarStore((state) => [state.collapsed]);
  const [openImageModal, setOpenImageModal] = useState(false);
  const [images, setImages] = useState<any[]>([]);
  const [user] = useAuthStore((state) => [state.user]);

  useEffect(() => {
    // const fetchModelImages = async (modelId) => {
    //   try {
    //     const galleryResponse = await networkService.get<any>(
    //       `${GET_MANUFACTURER}/models/${modelId}/gallery`
    //     );
    //     const imagePromises = galleryResponse.data.gallery.map(
    //       async (img: any) => {
    //         const imageResponse = await networkService.get<Blob>(
    //           `${GET_MANUFACTURER}/models/${modelId}/gallery/${img.id}`,
    //           null,
    //           { responseType: "blob" }
    //         );
    //         return {
    //           imageId: img.id,
    //           src: URL.createObjectURL(imageResponse),
    //         };
    //       }
    //     );
    //     setImages(await Promise.all(imagePromises));
    //   } catch (error) {
    //     const err = error as IError;
    //     console.error(`Error fetching images for model ${modelId}:`, err);
    //     toast.error(err.message);
    //     setImages([]);
    //   }
    // };
    const fetchModelImages = async (modelId: string) => {
      try {
        const galleryResponse = await networkService.get<any>(
          `${GET_MANUFACTURER}/models/${modelId}/gallery`
        );
        console.log("Gallery data:", galleryResponse.data);

        const imagePromises = galleryResponse.data.gallery.map(
          async (img: any) => {
            const imageResponse = await networkService.get<Blob>(
              `${GET_MANUFACTURER}/models/${modelId}/gallery/${img.id}`,
              null,
              { responseType: "blob" }
            );
            if (!(imageResponse instanceof Blob)) {
              throw new Error("Response is not a Blob");
            }
            return URL.createObjectURL(imageResponse);
          }
        );
        setImages(await Promise.all(imagePromises));
        console.log("Images:", images);
      } catch (error: any) {
        handleError({ error: error, message: error.message, level: "error" });
        setImages([]);
      }
    };

    const fetchDeviceDetails = async () => {
      if (!deviceId) {
        setError("No device ID provided.");
        setLoading(false);
        return;
      }

      try {
        const deviceResponse = await networkService.get<any>(
          `${DEVICE_DETAILS_API}/${deviceId}`
        );
        const deviceData = deviceResponse.data.device;
        if (deviceData) {
          // Fallback to probe data
          const probe: Probe = {
            id: deviceData.id || "N/A",
            externalDeviceId: deviceData.externalDeviceId || "N/A",
            nickName: deviceData.nickName || "N/A",
            licenseId: deviceData.licenses?.[0]?.id || "N/A",
            model: deviceData.model.modelName || "N/A",
            name: deviceData.name || "N/A",
            plan: deviceData.licenses?.[0]?.subscription.plan.name || "N/A",
            createdAt: deviceData.createdAt || "N/A",
            expiryDate: deviceData.expiryDate || "N/A",
            manufacturer: deviceData.model.manufacturer.name || "N/A",
            organization: {
              id: deviceData.organization?.id || "N/A",
              name: deviceData.organization?.name || "N/A",
            },
            licenseCreatedAt: deviceData.licenses?.[0]?.createdAt || "N/A",
            licenseExpiryDate: deviceData.licenses?.[0]?.expiryDate || "N/A",
          };
          setDeviceDetails(probe);
          fetchModelImages(deviceData.modelId);
        }
      } catch (error: any) {
        handleError({ error: error, message: error.message, level: "error" });
      } finally {
        setLoading(false);
      }
    };

    fetchDeviceDetails();
  }, [deviceId]);

  const handleReassignLicense = (e: any) => {
    if (
      deviceDetails &&
      deviceDetails.licenseId &&
      deviceDetails.licenseId !== "N/A"
    ) {
      navigate(`/probes/probe-reassign-license/${deviceId}`);
    } else {
      e.preventDefault();
      toast.error("No license assigned to re-assign.");
    }
  };

  if (loading) return <Loader />;
  if (error) return <ErrorPage error={error} />;

  return (
    <div
      className={`flex flex-col flex-grow px-12 py-10 overflow-hidden ${collapsed ? `${user?.role === UserRole.Admin ? "ml-20" : "md:ml-20"}` : `${user?.role === UserRole.Admin ? "ml-72" : "md:ml-72"}`} transition-all duration-300 pb-24`}>
      {/* <PageHeader title="Probe Details" showBack /> */}
      {/* <CustomModal
        isOpen={openImageModal}
        onClose={() => {
          setOpenImageModal(false);
        }}
        showCloseButton={true}>
        <div className="flex flex-col gap-3 items-center">
          <Carousel images={images} interval={6000} />
        </div>
      </CustomModal> */}

      <div className="relative">
        {/* Header Section */}
        <div className="flex gap-4 items-center mb-3 justify-start lg:justify-start shadow-none lg:shadow-none">
          <button
            onClick={() => navigate(-1)}
            className="border-2 border-black-600 p-2 rounded-full lg:hidden z-50"
          >
            <ChevronLeft size={24} />
          </button>
          <h1 className="text-[20px] lg:text-3xl font-bold font-space-grotesk text-center md:text-left ml-[3.4rem] md:ml-1 lg:ml-0">
            Probe Details
          </h1>
        </div>
        <div className="lg:block hidden border-t border-[var(--Stroke,#EFEFF4)] mt-5"></div>
        {/* Drop Shadow on Mobile, Horizontal Line on Desktop */}
        <div className="border-t lg:border-t-0 border-[var(--Stroke,#EFEFF4)] shadow-md lg:shadow-none absolute bottom-[-0.25rem] h-28 w-[1000px] left-[-48px] right-0"></div>
      </div>

      <div className="grid grid-cols-1 lg:grid-cols-2 gap-5 sm:gap-6 mt-8">
        {/* Probe Info */}
        <div className="sm:px-0">
          <h3 className="text-[22px] font-bold leading-7 text-Neutral-900 font-space-grotesk">
            Probe info
          </h3>
          <dl className="flex flex-col font-space-grotesk mt-4">
            <dt className="font-semibold text-lg">Manufacturer</dt>
            <dd className="mb-4 text-lg">{deviceDetails?.manufacturer}</dd>

            <dt className="font-semibold text-lg">Model</dt>
            <dd className="mb-4 text-lg">{deviceDetails?.model}</dd>

            <dt className="font-semibold text-lg">Serial Number</dt>
            <dd className="mb-4 text-lg">{deviceDetails?.externalDeviceId}</dd>

            <dt className="font-semibold text-lg">Name</dt>
            <dd className="mb-4 text-lg">{deviceDetails?.nickName || "N/A"}</dd>

            <dt className="font-semibold text-lg">Creation Date</dt>
            <dd className="text-lg">
              {deviceDetails?.createdAt ? formatDate(deviceDetails?.createdAt) : "N/A"}
            </dd>
          </dl>
        </div>

        {/* License Linked */}
        <div className="sm:px-0 lg:border-l border-t lg:border-t-0 border-[var(--Stroke,#D9D9D9)] lg:pl-8 pt-5 lg:pt-0">
          <h3 className="text-[22px] font-bold leading-7 text-Neutral-900 font-space-grotesk">
            License linked
          </h3>
          <dl className="flex flex-col font-space-grotesk my-4">
            <dt className="font-semibold text-lg">Plan</dt>
            <dd className="mb-4 text-lg">{deviceDetails?.plan || "N/A"}</dd>

            <dt className="font-semibold text-lg">ID</dt>
            <dd className="mb-4 text-lg">{deviceDetails?.licenseId}</dd>

            <dt className="font-semibold text-lg">Creation Date</dt>
            <dd className="mb-4 text-lg">
              {deviceDetails?.licenseCreatedAt
                ? formatDate(deviceDetails?.licenseCreatedAt)
                : "N/A"}
            </dd>

            <dt className="font-semibold text-lg">Expiration Date</dt>
            <dd className="mb-1 text-lg">
              {deviceDetails?.licenseExpiryDate
                ? formatDate(deviceDetails.licenseExpiryDate)
                : "N/A"}
            </dd>
          </dl>
        </div>
      </div>

      <div className="mt-6 mb-8 lg:my-8 flex md:flex-row flex-col flex-wrap gap-4">
        {deviceDetails?.id && deviceDetails.organization?.id && <div className="flex justify-center items-center lg:w-32 bg-angular-gradient rounded-full p-0.5">
          <Link
            to={`/probes/update-probe-details/${deviceDetails.organization.id}/${deviceDetails.id}?licenseId=${deviceDetails.licenseId}`}
            className="bg-rounded-full border text-center text-md font-semibold sm:text-base md:text-base font-space-grotesk w-full bg-white-a700 rounded-full p-1.5 py-2"
          >
            Edit
          </Link>
        </div>}
      </div>
    </div>
  );
}
